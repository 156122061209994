<template>
  <div class="confirm-popover">
    <el-popover :ref="`popover-${index}`" :title="title" :width="width" :placement="placement" :disabled="disabled">
      <div style="text-align: right; margin-top: 8px">
        <el-button type="text" size="mini" @click="closePopover()">取消</el-button>
        <el-button type="primary" size="mini" @click="confirm()">确定</el-button>
      </div>
      <el-button slot="reference" :type="type" round size="mini">{{ text }}</el-button>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {
    index: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: '确定操作吗？'
    },
    width: {
      type: [Number, String],
      default: 150
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: '删除'
    },
    type: {
      type: String,
      default: 'danger'
    }
  },

  data() {
    return {}
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    // 关闭弹出框
    closePopover() {
      this.$refs[`popover-${this.index}`].doClose()
    },

    // 确认事件
    confirm() {
      this.$emit('confirm')
      this.closePopover()
    }
  }
}
</script>

<style scoped lang="scss">
.confirm-popover {
}
</style>
